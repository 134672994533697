<template>
	<div>

	<!-- 	<div class="background d-flex align-items-center">
			<img src="../assets/img/l-img/bg.png" alt="" />
		</div> -->
		<div class="">
			<!-- <div style="z-index: 999;">
				 <headers @scroll="scroll" />
			</div> -->
		
			<div class=" w-100 d-flex justify-content-center">
				<img class="w-100 h-100" style="object-fit: cover;"  :src="$imgURL+newsPageBanner"/>
			</div>
			<div class="d-flex background justify-content-center  px-0-5 px-sm-0">
				<div class="top-1-25 wh-15 rounded-sm  container font text-muted position-relative bg-white" >
					<div class="p-0-5 p-sm-1">
						<el-breadcrumb separator-class="el-icon-arrow-right" style="line-height: 1.6;">
							<el-breadcrumb-item :to="{ path: '/' }"><span class="font">首页</span></el-breadcrumb-item>
							<el-breadcrumb-item :to="{ path: '/news' ,query:{type:data.type-1}}"><span class="font pointer">长脸叔叔动态</span></el-breadcrumb-item>
							<el-breadcrumb-item><span class="font pointer">{{data.title}}</span></el-breadcrumb-item>
						</el-breadcrumb>
					</div>
				
					<div class="p-0-5 px-sm-1">
						<div class="mt-0-5 mt-sm-1 font-md text-center text-color">{{data.title}}</div>
						<div class="d-flex flex-row  justify-content-center mt-0-5 mt-sm-1">
							<div>编辑：{{data.author||'暂无'}}</div>
							<div class="ml-2">发布日期：{{data.issue_time|formatDate}}</div>
						</div>
						<div class="w-100 bg-color my-1"></div>
						<div class="text px-0-5 text-center px-sm-1 w-100">
							<div class="w-100" v-html="data.content"></div>
						</div>
				
						<div class="text-color font mt-2 px-0-5 px-sm-1 ">
							<div class="pointer" @click="getPage(data.on_id)" v-if="data.on_id!=0">上一篇：{{on_title}}</div>
							<div class="mt-1 pointer" @click="getPage(data.next_id)" v-if="next_title">下一篇：{{next_title}}</div>
						</div>
					</div>
				
					<div class="mt-2 ">
						<div class="font-md text-color px-1">相关阅读</div>
				
						<div class="d-flex flex-row mt-1 flex-wrap">
							<div @click="getPage(item.id)" class=" col-lg-3 col-sm-6   col-md-4 p-0 px-0-5 px-sm-1 col-6 pb-1 pb-sm-2" v-for="(item,index) in data.list">
								<div class="bg-light1  pointer" style="overflow: hidden;border-radius: 0.1rem;">
									<div style="height: 1.5rem;">
										<img class="w-100 h-100" style="object-fit: cover;" :src="$imgURL+item.image" alt="">
									</div>
									<div class="p-0-5 p-sm-1 text-dark">
										<div class="font-smd text-over">{{item.title}}</div>
										<div class="font text-over">{{item.createtime|formatDate}}</div>
										<el-divider></el-divider>
										<div class="text-over-5 text-muted h-12 font" >{{item.desc}}</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				
				
				</div>
				
			</div>
			
			<!-- <footers id="footer" /> -->

		</div>

	</div>
</template>

<script>
	export default {
		inject:['reload'],
		data() {
			return {
				data: {},
				next_title: '',
				on_title: '',
				newsPageBanner:''
			}
		},
		filters: {
			formatDate: function(value) {
				if(value==null ||value==0){
					return '暂无'
				}
				let date = new Date(value*1000);
				let y = date.getFullYear();
				let MM = date.getMonth() + 1;
				MM = MM < 10 ? ('0' + MM) : MM;
				let d = date.getDate();
				d = d < 10 ? ('0' + d) : d;
				let h = date.getHours();
				h = h < 10 ? ('0' + h) : h;
				let m = date.getMinutes();
				m = m < 10 ? ('0' + m) : m;
				let s = date.getSeconds();
				s = s < 10 ? ('0' + s) : s;
				return y + '-' + MM + '-' + d
			}
		},
		mounted() {
			let id = this.$route.query.id
			console.log(id);
			this.getData(id)
			this.getNewsPageBanner()
		},
		methods: {
			scroll() {
				document.getElementById('footer').scrollIntoView()
			},
			getNewsPageBanner() {
			  var params = new URLSearchParams();
			  params.append("key", 'banner_news');
			  this.$axios({
			    method: "get",
			    url: this.$apiURL + "api/config/config?"+params
			  }).then((res) => {
			    if (res.code < 1) {
			      console.log("网络错误");
			      return false;
			    }
			  
			    if(res.data.data){
			      this.newsPageBanner = res.data.data
			    }
			  });
			},
			getPage(id){
				this.reload()
				 this.$router.push({path:'/news_detail',query:{id:id}})
			},
			getData(id) {
				var params = new URLSearchParams();
				params.append("id", id);
				this.$axios({
					method: "post",
					url: this.$apiURL + "api/news/getNewsInfo",
					data: params
				}).then((res) => {
					if (res.code < 1) {
						console.log("网络错误");
						return false;
					}

					if (res.data.data) {


						this.data = res.data.data




						var params1 = new URLSearchParams();
						params1.append("id", res.data.data.next_id);
						this.$axios({
							method: "post",
							url: this.$apiURL + "api/news/getNewsInfo",
							data: params1
						}).then((res) => {
							if (res.code < 1) {
								console.log("网络错误");
								return false;
							}

							if (res.data.data) {

								this.next_title = res.data.data.title
							}
						});
						if (res.data.data.on_id != 0) {
							var params2 = new URLSearchParams();
							params2.append("id", res.data.data.on_id);
							this.$axios({
								method: "post",
								url: this.$apiURL + "api/news/getNewsInfo",
								data: params2
							}).then((res) => {
								if (res.code < 1) {
									console.log("网络错误");
									return false;
								}

								if (res.data.data) {
									this.on_title = res.data.data.title

									console.log(res.data.data.title);
								}
							});
						}





					}
				});
			}
		}
	}
</script>

<style>

	.bg-light1{
		background-color: rgba(242,242,242);
	}
	@media (max-width: 576px) {
		img{
			width:100%
		}
		.top-1-25{
			top: -0.2rem!important;
		}
		.h-12{
			height: 0.56rem!important;
		}
	}
	.h-12{
		height: 1.2rem;
	}
	.top-1-25{
		top: -1.125rem;
	}
	.wh-15{
		width: 1500px;
	}
	.el-breadcrumb{
			font-size: 0.14rem;
	}
	.el-divider--horizontal {
		margin: 0.1rem 0;
	}

	.text-over {
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 1;
		overflow: hidden;
	}

	.text-over-5 {
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 5;
		overflow: hidden;
	}

	.text div {
		line-height: 2;
	}

	.text-indent {
		text-indent: 0.2rem;
	}

	.bg-color {
		background-color: #A0D9F6;
		height: 0.02rem;

	}

	.text-color {
		color: #A0D9F6;
	}

	.background {
		background: url("../assets/img/bg.png");
		background-size: 100%;
		/* width: 100%;
		height: 100vh;
		z-index: -1;
		position: absolute;
		overflow: hidden; */
		/* background: url("../assets/img/l-img/bg.png");
		background-size: 100% 100%;
		height: 100%;
		position: fixed;
		width: 100% */
	}
</style>
